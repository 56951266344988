import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { GlobalContext } from '../GlobalContext';
import { Link } from 'react-router-dom';

const HighscorePage = ({ viewType }) => {
    const navigate = useNavigate();
    const { date } = useParams(); // Get the date from the URL
    const { playerId, setPlayerId, accessToken } = useContext(GlobalContext); // Get the current player ID from the context
    const [scores, setScores] = useState([]); // State to store the high scores
    const [currentPlayerScore, setCurrentPlayerScore] = useState(null); // State to store the current player's score
    const [currentPlayerScoreIsInTop5, setCurrentPlayerScoreIsInTop5] = useState(false); // State to check if the current player's score is in the top 5
    const [rewardUrl, setRewardUrl] = useState(''); // State to store the reward URL from the ranking response
    const [loading, setLoading] = useState(true); // State to track loading status
    const [errorMessage, setErrorMessage] = useState(''); // State for error handling

    // Define initializeData with the if/else logic
    const initializeData = async () => {
        if (viewType === 'view-winner' || viewType === 'check-result') {
            // For 'view-winner', fetch the current game and ranking data
            await fetchCurrentGameDate();
        } else if (date) {
            // For other modes, fetch the ranking data using the provided date
            await fetchRankingData(date);
        }
    };

    useEffect(() => {
        initializeData(); // Call the initializeData function
    }, [date, playerId, viewType]);

    // Fetch the highscore data based on the date
    const fetchRankingData = async (rankingId) => {
        try {
            const headers = new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            });

            if (accessToken) {
                headers.append('Authorization', `Bearer ${accessToken}`);
            }

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/ranking/${rankingId}`, {
                method: 'GET',
                headers: headers
            });

            if (!response.ok) {
                if (response.status === 404) {
                    navigate('/highscores');
                    setErrorMessage('Nie znaleziono rankingu dla podanej daty.');
                } else {
                    navigate('/highscores');
                    throw new Error('Błąd podczas pobierania wyników.');
                }
                return;
            }

            const data = await response.json();

            // Set rewards
            setRewardUrl(data.reward.ranking_url);

            // Find the current player's score
            const playerScore = data.ranking.find(score => score.player_id === playerId);
            if (playerScore && playerScore.order > 6) {
                setCurrentPlayerScore(playerScore);
            }

            // Set the top 6 scores in state
            setScores(data.ranking.slice(0, 6));

            // Check if the current player's score is in the top 6
            setCurrentPlayerScoreIsInTop5(playerScore && playerScore.order <= 6);
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchCurrentGameDate = async () => {
        try {
            const headers = new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            });

            if (accessToken) {
                headers.append('Authorization', `Bearer ${accessToken}`);
            }

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/game/current`, {
                method: 'GET',
                headers: headers
            });

            if (!response.ok) {
                if (response.status === 404) {
                    navigate('/game-status/before-game');
                    setErrorMessage('Nie znaleziono gry na dzisiaj.');
                } else if (response.status === 403) {
                    navigate('/game-status/before-game');
                    setErrorMessage('Gra jeszcze się nie rozpoczęła.');
                } else {
                    navigate('/game-status/before-game');
                    throw new Error('Błąd podczas pobierania aktualnej gry.');
                }
                return;
            }

            const data = await response.json();
            const gameId = data.id;

            // Fetch the ranking data for the current game
            const response2 = await fetch(`${process.env.REACT_APP_API_BASE_URL}/game/${gameId}/ranking`, {
                method: 'GET',
                headers: headers
            });

            if (!response2.ok) {
                throw new Error('Błąd podczas pobierania wyników.');
            }

            const data2 = await response2.json();
            setRewardUrl(data2.reward.ranking_url);

            // Set the top 5 scores in state
            setScores(data2.ranking.slice(0, 5));

            // Find the current player's score
            const playerScore = data2.ranking.find(score => score.player_id === playerId);

            if (playerScore) {
                setCurrentPlayerScore(playerScore);
            }

            // Check if the current player's score is in the top 5
            setCurrentPlayerScoreIsInTop5(playerScore && playerScore.order <= 5);
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setLoading(false);
        }
    };

    const getTitleText = () => {
        switch (viewType) {
            case 'check-result':
                return 'RANKING';
            case 'view-date':
                return 'ZOBACZ KTO WYGRAŁ';
            case 'view-winner':
                return 'RANKING';
            default:
                return 'RANKING';
        }
    }

    // Determine the header text based on the viewType
    const getHeaderText = () => {
        switch (viewType) {
            case 'check-result':
                return 'Sprawdź swój wynik';
            case 'view-date':
                return `Wyniki z ${date}`;
            case 'view-winner':
                return `Wyniki z ${date}`;
            default:
                return `Wyniki z ${date}`;
        }
    };

    return (
        <div className="relative flex items-center justify-center min-h-screen bg-white overflow-hidden">
            <img
                src="/assets/cc background heart.svg"
                className="absolute bottom-[0] left-1/2 transform -translate-x-1/2"
                style={{ width: '1468px', zIndex: 0 }}
            />

            <div className="relative w-full mx-auto" style={{ minHeight: '100vh', maxWidth: '500px' }}>
                <img src='/assets/heart 4.svg' className="absolute w-full w-[100%]" style={{ minHeight: '320px', marginTop: '65px', transform: 'scale(0.9)' }} />
                <img
                    src={rewardUrl}
                    className="w-full w-[100%] absolute"
                    style={{ minHeight: '320px' }}
                />
                <div className='relative' style={{ height: '533px', marginBottom: '-100px' }}>
                </div>

                {viewType !== 'view-winner' ? (
                    <Link to="/highscores" className='cc-button-secondary absolute top-6 left-6 flex items-center justify-center' style={{ fontSize: 28, width: '57px', height: '44px', zIndex: 10 }}>
                        <img
                            src="/assets/back arrow.svg"
                            alt="POWRÓT"
                            style={{ width: '25px', height: '28px', maxWidth: 'unset' }}
                        />
                    </Link>
                ) : (
                    <Link to="/menu" className='cc-button-secondary absolute z-10 top-6 left-6 flex items-center justify-center text-black text-xl font-bold mr-2 cc-font-bold' style={{ width: '121px' }}>
                        <img
                            src='/assets/cc logo.svg'
                            alt="Coca Cola & Oreo"
                            className="mr-2" style={{ width: '35px' }}
                        />
                        MENU
                    </Link>
                )}
                <div className="relative max-w-xs mx-auto px-4 mt-6" style={{ minHeight: '405px' }}>

                    {/* Bubbles */}
                    <img
                        src='/assets/bubbles 3.svg'
                        alt=""
                        className="absolute z-0"
                        style={{ width: '30px', height: 'auto', top: '-80px', right: '0px', transform: 'scale(1.1) ' }}
                    />
                    <img
                        src='/assets/bubbles 4.svg'
                        alt=""
                        className="absolute z-0"
                        style={{ width: '30px', height: 'auto', top: '60px', left: '-5px' }}
                    />
                    <img
                        src='/assets/bubbles 1.svg'
                        alt=""
                        className="absolute z-0"
                        style={{ width: '30px', height: 'auto', top: '250px', right: '-20px', transform: 'scale(1.1) rotate(-45deg)' }}
                    />

                    <div className="text-2xl text-center cc-font-bold mt-2" style={{ fontSize: 55, lineHeight: '1', marginLeft: '-40px', marginRight: '-40px' }}>
                        {getTitleText()}
                    </div>
                    <div className="text-2xl text-center cc-font-bold mt-3" style={{ fontSize: "36px", lineHeight: '1' }}>
                        {getHeaderText()}
                    </div>
                    <div className="mt-2 cc-button-secondary" style={{ fontSize: "22px" }}>
                        <ul className="text-left">
                            {loading ? (
                                <>
                                    <li className="cc-font-bold flex justify-between items-center bg-gray-200 rounded-lg animate-pulse mt-1 mb-3">
                                        <span className="inline-block w-10 h-5 bg-gray-300 rounded"></span>
                                        <span className="ml-2 flex-grow w-32 h-5 bg-gray-300 rounded"></span>
                                        <span className="w-10 h-5 bg-gray-300 rounded"></span>
                                    </li>
                                </>
                            ) : errorMessage ? (
                                <li className="cc-font-light text-center text-gray-500" style={{ fontSize: '80%' }}>
                                    {errorMessage}
                                </li>
                            ) : (
                                scores.length > 0 ? (
                                    scores.map((score) => (
                                        <li
                                            key={score.player_id}
                                            className={`cc-font-bold flex justify-between items-center ${score.player_id === playerId ? 'text-[#00B707]' : 'bg-white text-black'} rounded-lg`}
                                        >
                                            <span className="text-gray-500 inline-block" style={{ minWidth: '28px' }}>{score.order.toString().padStart(2, '0')}</span>
                                            <span className="ml-2 flex-grow" style={{ color: score.player_id === playerId ? '#00B707' : 'inherit' }}>{score.nick}</span>
                                            <span style={{ color: score.player_id === playerId ? '#00B707' : 'inherit' }}>{score.time_to_display}</span>
                                        </li>
                                    ))
                                ) : (
                                    <li className="cc-font-light text-center text-gray-500" style={{ fontSize: '80%' }}>
                                        brak wyników
                                    </li>
                                )
                            )}
                            {currentPlayerScore && currentPlayerScore.order > 5 && (
                                <>
                                    <li className='cc-font-bold'>
                                        ...
                                    </li>
                                    <li
                                        className="cc-font-bold flex justify-between items-center text-[#00B707] rounded-lg"
                                    >
                                        <span className="text-gray-500 inline-block" style={{ minWidth: '28px' }}>{currentPlayerScore.order.toString().padStart(2, '0')}</span>
                                        <span className="ml-2 flex-grow" style={{ color: '#00B707' }}>{currentPlayerScore.nick}</span>
                                        <span style={{ color: '#00B707' }}>{currentPlayerScore.time_to_display}</span>
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                    {currentPlayerScore && (
                        <div className="text-center cc-font-bold px-1 mt-2 mb-12" style={{ fontSize: "20px", lineHeight: '1.2em' }}>
                            {currentPlayerScoreIsInTop5 ? 'Gratulacje! Na ten moment wygrałeś nagrodę, ale poczekaj do końca rozgrywki.' : 'Tym razem nie udało się zdobyć nagrody. Ale nie przejmuj się, możesz tu wrócić następnym razem i spróbować jeszcze raz.'}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default HighscorePage;
