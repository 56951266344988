import React, { createContext, useState } from 'react';

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [code, setCode] = useState('');
  const [receivedData, setReceivedData] = useState(null);
  const [playerId, setPlayerId] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [gameData, setGameData] = useState(null);

  const clearData = () => {
    setCode('');
    setAccessToken(null);
    setGameData(null);
    // setReceivedData(null);
    // setPlayerId(null);
  };

  return (
    <GlobalContext.Provider value={{
      code,
      code,
      setCode,
      receivedData,
      setReceivedData,
      playerId,
      setPlayerId,
      accessToken,
      setAccessToken,
      gameData,
      setGameData,
      clearData,
    }}>
      {children}
    </GlobalContext.Provider>
  );
};
